<template>
  <div>
      <el-row style="background-color: #FFFFFF;padding-left: 20PX;">
        <content-title :nav="nav"></content-title>
      </el-row>
    <div class="UserFacts">
      <div class="Facts">
        <img class="img" src="../../assets/yh1.png" alt="" />
        <div class="div-p">
          <p class="p-1">1</p>
          <p class="p-2">用户总数</p>
        </div>
      </div>
      <div class="Facts">
        <img class="img" src="../../assets/yh2.png" alt="" />
        <div class="div-p">
          <p class="p-1">1</p>
          <p class="p-2">用户总数</p>
        </div>
      </div>
    </div>
    <div class="nr-box">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="登录账号">
          <el-input v-model="login_name"></el-input>
        </el-form-item>
        <el-form-item label="所属客服">
          <el-input v-model="nav.firstNav"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-input v-model="nav.firstNav"></el-input>
        </el-form-item>
        <el-form-item label="所属区域">
          <el-input v-model="nav.firstNav"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="search()">查询</el-button>
        </el-form-item>
      </el-form>

      <div>
        <el-table ref="multipleTable" :data="user_list" tooltip-effect="dark">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="user_id" label="用户ID"> </el-table-column>
          <el-table-column prop="nick_name" label="用户昵称"> </el-table-column>
          <el-table-column prop="login_name" label="登录账号"  width="150"></el-table-column>
          <el-table-column prop="login_time" label="登录时间"  width="200"></el-table-column>
          <el-table-column prop="create_time" label="注册时间"  width="200"></el-table-column>
          <el-table-column prop="address" label="所属区域"></el-table-column>
          <el-table-column prop="address" label="状态"></el-table-column>
          <el-table-column prop="address" label="操作"  width="180">
            <template slot-scope="scope">
              <div class="flex jc-b btn-group" style="font-size: 14px" >
                <span class="cky2s" @click="toDetails(scope.row)">查看详情</span>
                <span class="ckys1" style="margin: 0 10px">重置密码</span>
                <span class="ckys1">冻结</span>
                <span class="ckys1">限制登录</span>
                <span class="ckys1">注销</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <paging-fy
        @currentPageChange="currentPageChange"
        :currentPage="1"
        :total="12"
      ></paging-fy>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "用户管理中心", secondNav: "用户列表" },
      user_list: [],
      currentPage: 1,
      is_loading: true,
      login_name: null,
      nick_name: "",
      user_total: 0,
      
    };
  },
  methods: {
    toDetails(row) {
      this.$router.push({
        name: "Userdetails",
        params: {
          id: row.user_id,
        },
      });
    },


    getUserList() {
      this.is_loading = true;
      let url = "/user/user/queryByMessage";
      let data = {
        pageSize: 10,
        currentPage: this.currentPage,
        // status: 1,
        // is_teach_paypal: true,
        login_name: this.login_name,
        // register_platform: 'wx',
      };
      this.$axios
        .get(url, {
          params: {
            ...data,
          },
        })
        .then((res) => {
          this.user_list = res.data.data.rows.filter((item) => {
            return item.status != 5;
          });
          this.user_total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getUserList();
    },

    shelve(row) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    update(row, status) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },

    payPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },

    listTab(tab, event) {},
  },

  mounted() {
    this.getUserList("url1");

  },
}
</script>

<style   lang="less">
.UserFacts {
  display: flex;
  background-color: #ffffff;
  padding: 10px 20px 20px 20px;
 flex-wrap:wrap ;
  .Facts {
    display: flex;
    width: 220px;
    height: 72px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 17px 0 14px 30px;
    margin-right: 50px;
    .img {
      width: 34px;
      height: 38px;
      margin-right: 20px;
    }
    .div-p {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      .p-1 {
        color: #434343;
      }
      .p-2 {
        color: #808080;
      }
    }
  }
}
.nr-box {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 20px 0 20px;
  .cky2s {
    color: #ff7e00;
  }
  .ckys1 {
    color: #f56c6c;
  }
}
.el-table {
  margin: 0;
}
.el-form-item {
  margin-right: 50px !important;
}
.cell {
  text-align: center;
}

.btn-group{
  flex-wrap: wrap;
  justify-content: space-between;
  span{
    margin-right:10px ;
  }
}
</style>
